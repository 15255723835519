import axios from "../index";

// B端公众号登录
export function login(data) {
  return axios.postForm("/bapi/manager/login/login", data);
}

// 忘记密码保存
export function updatePassword(data) {
  return axios.postForm("/bapi/manager/login/updatePassword", data);
}

// 获取验证码
export function getSmsCode(data) {
  return axios.postForm("/bapi/manager/login/getSmsCode", data);
}

// 获取公众号返回code
export function getGzhCode(data) {
  return axios.postForm("/bapi/managerBusiness/getGzhCode", data);
}
